import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { noCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import { Box, Stack, List, Badge, Button, Avatar, Tooltip, Divider, IconButton, Typography, ListItemText, ListSubheader, ListItemAvatar, ListItemButton, } from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { dispatch, useSelector } from '../../../redux/store';
import { getNotifications, markAllSeen, } from '../../../redux/slices/notifications/notificationThunk';
import { Container } from '@mui/system';
import LoadingScreen from '../../../components/loading-screen';
import { OneNotificationItem } from './NotificationItem';
import { useSnackbar } from 'notistack';
import { markAllAsSeen } from '../../../redux/slices/notifications/notificationSlice';
import { useLocales } from '../../../locales';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from '../../../routes/paths';
// ----------------------------------------------------------------------
export default function NotificationsPopover() {
    const [openPopover, setOpenPopover] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { translate } = useLocales();
    const navigate = useNavigate();
    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };
    const handleClosePopover = () => {
        setOpenPopover(null);
    };
    const handleMarkAllAsRead = async () => {
        dispatch(markAllSeen()).then((res) => {
            if (res?.payload?.statusCode === 404) {
                enqueueSnackbar(res?.payload?.message, { variant: 'error' });
            }
            else {
                dispatch(markAllAsSeen());
                enqueueSnackbar(`${translate(`marked_seen`)}`);
            }
        });
    };
    useEffect(() => {
        dispatch(getNotifications({ page: 1, limit: 10000 }));
    }, []);
    const data = useSelector((state) => state.notifications);
    const totalUnRead = data?.data.filter((item) => item.seen === false).length;
    return (_jsxs(_Fragment, { children: [_jsx(IconButtonAnimate, { color: openPopover ? 'primary' : 'default', onClick: handleOpenPopover, sx: { width: 40, height: 40, background: '#F1F7FF' }, children: _jsx(Badge, { badgeContent: totalUnRead, color: "error", children: _jsx(Iconify, { icon: "eva:bell-fill", color: "#2788E7" }) }) }), _jsx(MenuPopover, { open: openPopover, onClose: handleClosePopover, sx: { width: 360, p: 0 }, children: data?.loading && !data.error ? (_jsx(Typography, { children: _jsx(LoadingScreen, {}) })) : (_jsxs(Container, { sx: { padding: '5px !important' }, children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', py: 2, px: 2.5 }, children: [_jsxs(Box, { sx: { flexGrow: 1 }, children: [_jsx(Typography, { variant: "subtitle1", children: "Notifications" }), _jsxs(Typography, { variant: "body2", sx: { color: 'text.secondary' }, children: ["Vous avez ", totalUnRead, " messages non lus."] })] }), totalUnRead > 0 && (_jsx(Tooltip, { title: " Tout marquer comme lu", children: _jsx(IconButton, { color: "primary", onClick: handleMarkAllAsRead, children: _jsx(Iconify, { icon: "eva:done-all-fill" }) }) }))] }), _jsx(Divider, { sx: { borderStyle: 'dashed' } }), _jsx(Scrollbar, { sx: { height: { sm: 'auto', lg: 'auto', md: 'auto' } }, children: _jsx(List, { disablePadding: true, subheader: _jsx(ListSubheader, { disableSticky: true, sx: { py: 1, px: 2.5, typography: 'overline' }, children: "NOUVELLE" }), sx: { overflow: 'auto', height: '380px' }, children: data?.data?.slice(0, 3).map((el, index) => {
                                    return (_jsx(OneNotificationItem, { notification: el, handleClosePopover: handleClosePopover }, index));
                                }) }) }), _jsx(Divider, { sx: { borderStyle: 'dashed' } }), _jsx(Box, { sx: { p: 1 }, children: _jsx(Button, { fullWidth: true, disableRipple: true, onClick: () => {
                                    handleClosePopover();
                                    navigate(PATH_DASHBOARD.notifications);
                                }, children: "Voir tout" }) })] })) })] }));
}
export function NotificationItem({ notification }) {
    const { avatar, title } = renderContent(notification);
    return (_jsxs(ListItemButton, { sx: {
            py: 1.5,
            px: 2.5,
            mt: '1px',
            ...(notification.isUnRead && {
                bgcolor: 'action.selected',
            }),
            borderRadius: '12px',
            margin: '5px',
        }, children: [_jsx(ListItemAvatar, { children: _jsx(Avatar, { sx: { bgcolor: 'background.neutral' }, children: avatar }) }), _jsx(ListItemText, { disableTypography: true, primary: title, secondary: _jsxs(Stack, { direction: "row", sx: { mt: 0.5, typography: 'caption', color: 'text.disabled' }, children: [_jsx(Iconify, { icon: "eva:clock-fill", width: 16, sx: { mr: 0.5 } }), _jsx(Typography, { variant: "caption", children: fToNow(notification.createdAt) })] }) })] }));
}
// ----------------------------------------------------------------------
export function renderContent(notification) {
    const title = (_jsxs(Typography, { variant: "subtitle2", children: [notification.title, _jsxs(Typography, { component: "span", variant: "body2", sx: { color: 'text.secondary' }, children: ["\u00A0 ", noCase(notification.description)] })] }));
    if (notification.type === 'order_placed') {
        return {
            avatar: _jsx("img", { alt: notification.title, src: "/assets/icons/notification/ic_package.svg" }),
            title,
        };
    }
    if (notification.type === 'order_shipped') {
        return {
            avatar: _jsx("img", { alt: notification.title, src: "/assets/icons/notification/ic_shipping.svg" }),
            title,
        };
    }
    if (notification.type === 'mail') {
        return {
            avatar: _jsx("img", { alt: notification.title, src: "/assets/icons/notification/ic_mail.svg" }),
            title,
        };
    }
    if (notification.type === 'chat_message') {
        return {
            avatar: _jsx("img", { alt: notification.title, src: "/assets/icons/notification/ic_chat.svg" }),
            title,
        };
    }
    return {
        avatar: notification.avatar ? _jsx("img", { alt: notification.title, src: notification.avatar }) : null,
        title,
    };
}
